<template>
  <div class="form-item">
    <div class="label" v-if="label != ''">
      <i v-if="required">*</i>{{ label }}
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "formView",
  props: {
    label: {
      type: String,
      default: () => {
        return "";
      },
    },
    required: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {};
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.form-item {
  .label {
    display: flex;
    align-items: center;
    color: #1a1a1a;
    font-size: 16px;
    margin-bottom: 5px;
    i {
      color: #ff1a1a;
      margin-right: 2px;
    }
  }
}
</style>