<template>
  <div class="stronghold-block">
    <div class="txtView">
      <div>
        <h3>Bareiss營運據點</h3>
        <p>更多 Bareiss 各地區分公司的資訊。</p>
        <router-link class="link" to="/operation_base">營運據點</router-link>
      </div>
    </div>
    <div class="imgView">
      <img :src="product_support_banner" alt="" srcset="" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      product_support_banner: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.$http.getProductSupportBanner().then((res) => {
        if (res.code == 200) {
          this.product_support_banner = res.data.product_support_banner;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.stronghold-block {
  display: flex;
  padding: 0 30px;
  margin-bottom: 30px;
  @include respond-to(max1439) {
    padding-bottom: 20px;
  }
  @include respond-to(sm) {
    flex-direction: column;
    padding: 0 5% 20px;
  }
  > * {
    width: 50%;
    @include respond-to(sm) {
      width: 100%;
    }
  }
  .txtView {
    background-image: url("../../assets/images/b1.jpg");
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    min-height: 258px;
    h3 {
      font-size: 26px;
      @include respond-to(sm) {
        font-size: 20px;
      }
    }
    p {
      font-size: 16px;
      margin: 20px 0;
      @include respond-to(sm) {
        font-size: 14px;
      }
    }
    .link {
      width: 120px;
      height: 48px;
      background-color: #0075c1;
      border: 1px solid #0075c1;
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #fff;
      &:hover {
        color: #0075c1;
        background-color: transparent;
      }
      @include respond-to(sm) {
        font-size: 14px;
        min-height: auto;
      }
    }
  }
  .imgView {
    img {
      width: 100%;
      display: block;
    }
    @include respond-to(max1439) {
      min-height: 258px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 100%;
        width: auto;
      }
    }
    @include respond-to(sm) {
      min-height: auto;
      width: 100%;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>