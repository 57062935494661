<template>
  <div class="content">
    <div class="main">
      <div class="upView">
        <h3>產品咨詢</h3>
      </div>
      <section class="oneView">
        <div class="title">聯絡訊息</div>
        <div class="formItem one">
          <form-slot label="尊稱" required>
            <el-select v-model="honored" placeholder="" clearable>
              <el-option label="女士" value="女士"></el-option>
              <el-option label="先生" value="先生"></el-option>
            </el-select>
          </form-slot>
          <form-slot label="姓" required>
            <el-input v-model="surname" clearable></el-input>
          </form-slot>
          <form-slot label="名" required>
            <el-input v-model="name" clearable></el-input>
          </form-slot>
        </div>
        <div class="formItem two">
          <form-slot label="電子郵箱" required>
            <el-input v-model="email" clearable></el-input>
          </form-slot>
          <form-slot label="電話" required>
            <el-input v-model="phone" clearable></el-input>
          </form-slot>
          <form-slot label="職務">
            <el-input v-model="position" clearable></el-input>
          </form-slot>
        </div>
        <form-slot label="公司全名" required>
          <el-input v-model="company_name" clearable></el-input>
        </form-slot>
        <form-slot label="公司地址" required>
          <el-input v-model="company_address" clearable></el-input>
        </form-slot>
        <form-slot label="您的訊息" style="margin-bottom: 0" required>
          <el-input v-model="other_messages" clearable></el-input>
        </form-slot>
      </section>
      <div class="agreementView">
        <el-checkbox v-model="agreement">我已閱讀並同意</el-checkbox>
        <router-link class="link" to="/privacy_policy">隱私權聲明</router-link>
      </div>
      <el-button
        type="primary"
        class="submit"
        @click="submit"
        :disabled="!agreement"
        round
        >送 出</el-button
      >
    </div>
    <stronghold-block />
  </div>
</template>

<script>
import formSlot from "@/components/slot/formView";
import strongholdBlock from "@/components/stronghold-block";
export default {
  name: "ProductConsulting",
  components: {
    formSlot,
    strongholdBlock,
  },
  data() {
    return {
      honored: "",
      surname: "",
      name: "",
      email: "",
      phone: "",
      position: "",
      company_name: "",
      company_address: "",
      other_messages: "",

      agreement: false,
    };
  },
  mounted() {},
  methods: {
    submit() {
      let parameter = {
        honorific: this.honored,
        lastname: this.surname,
        firstname: this.name,
        email: this.email,
        tel: this.phone,
        job: this.position,
        company_name: this.company_name,
        company_address: this.company_address,
        other: this.other_messages,
        type: 3,
      };

      if (parameter.honorific == "") {
        this.$message({
          message: "請選擇尊稱",
          type: "warning",
        });
        return;
      }
      if (parameter.lastname == "") {
        this.$message({
          message: "請輸入姓",
          type: "warning",
        });
        return;
      }
      if (parameter.firstname == "") {
        this.$message({
          message: "請輸入名",
          type: "warning",
        });
        return;
      }
      if (parameter.email == "") {
        this.$message({
          message: "請輸入電子郵箱",
          type: "warning",
        });
        return;
      }
      if (parameter.tel == "") {
        this.$message({
          message: "請輸入電話",
          type: "warning",
        });
        return;
      }
      if (parameter.company_name == "") {
        this.$message({
          message: "請輸入公司全名",
          type: "warning",
        });
        return;
      }
      if (parameter.company_address == "") {
        this.$message({
          message: "請輸入公司地址",
          type: "warning",
        });
        return;
      }
      if (parameter.other == "") {
        this.$message({
          message: "請輸入您的訊息",
          type: "warning",
        });
        return;
      }
      this.$http.postData(parameter).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.honored = "";
          this.surname = "";
          this.name = "";
          this.email = "";
          this.phone = "";
          this.position = "";
          this.company_name = "";
          this.company_address = "";
          this.other_messages = "";
          this.agreement = false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/variables.scss";
.main {
  width: 1400px;
  margin: 0 auto;
  padding-top: 100px;
  @include respond-to(max1439) {
    width: 95%;
  }
  @include respond-to(sm) {
    padding-top: 20px;
  }
  .upView {
    margin-bottom: 50px;
    @include respond-to(sm) {
      margin-bottom: 10px;
    }
    h3 {
      font-size: 40px;
      color: #1a1a1a;
      font-weight: normal;
      margin-bottom: 20px;
      @include respond-to(sm) {
        font-size: 26px;
        margin-bottom: 0;
      }
    }
    .introduce {
      font-size: 16px;
      color: #333;
    }
  }
  section {
    .title {
      padding-bottom: 10px;
      font-size: 20px;
      color: #1a1a1a;
      border-bottom: 1px solid #e6e6e6;
      margin-bottom: 30px;
    }
    /deep/ .form-item {
      margin-bottom: 32px;
    }
    &.oneView {
      .formItem {
        display: flex;
        align-items: center;
        > * {
          margin-right: 50px;
        }
        &.one {
          @include respond-to(sm) {
            flex-wrap: wrap;
          }
          /deep/ .form-item {
            width: 108px;
            @include respond-to(sm) {
              width: 100%;
              margin-right: 0;
              .el-select {
                width: 100%;
              }
            }
          }
        }
        &.two {
          @include respond-to(sm) {
            flex-wrap: wrap;
          }
          /deep/ .form-item {
            width: 300px;
            @include respond-to(sm) {
              width: 100%;
              margin-right: 0;
            }
          }
        }
      }
    }
  }
  .agreementView {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #333333;
    margin-top: 30px;
    .link {
      font-size: 16px;
      color: #0075c1;
    }
  }
  .submit {
    display: block;
    width: 120px;
    margin: 70px auto 90px;
  }
}
</style>